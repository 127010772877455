<template>
  <div>
    <div class="essay">
      <div class="essayTitle">{{ list.Title }}</div>
      <div class="rikind"
        style="text-align: right;">{{ list.AddTime }}</div>
      <div v-if="list.ThematicUrl"
        class="essayImg">
        <img :src="list.ThematicUrl"
          alt="" />
      </div>
      <p class="essayContent"
        v-html="list.Content"></p>
    </div>
  </div>
</template>
<script>
import { WeGetArticleInfo } from "@/api/Pension";
export default {
  data () {
    return {
      list: {},
    };
  },
  methods: {
    // 获取资讯详情
    getRealInfo () {
      console.log(this.$route);
      WeGetArticleInfo({ Id: this.$route.params.Id }).then((res) => {
        this.list = res.data.data;
        console.log(this.list);
      });
    },
  },
  mounted () {
    this.getRealInfo();
  },
};
</script>
<style scoped>
/deep/.essay .essayContent img {
  width: 100% !important;
  padding: 10px 0;
}
</style>